import Axios from "axios";
let token = localStorage.getItem("accessToken") || "";

const axios = Axios.create({
  // baseURL: `https://api.eylo.com.bd/api/v1`, // testing

  baseURL:
    process.env.NODE_ENV === "development"
      ? `http://localhost:4023/api/v1`
      : `https://api.eylo.com.bd/api/v1`, // testing

  headers: {
    Accept: "application/json",
    Authorization: `Bearer ${token}`,
  },
});

export default axios;
